import React, {useState} from "react";
import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
import DefaultHeader from "../containers/DefaultLayout/DefaultHeader";
import {Container} from "reactstrap";
import Card from "../ui-kit/src/atoms/Card"
import styles from "./NoBrandsAvailable.module.scss"
import MidButton from "../ui-kit/src/general/MidButton";
import AddCompanyModal from "./AddCompanyModal";
import {SidebarLink, SidebarSeparator, SidebarTitle} from "../ui-kit/src/sidebar/SidebarComponents";
import {useLocation} from "react-router-dom";
import featureFlags from "../featureFlags";

export default function NoBrandsAvailable({user}) {
  const location = useLocation();
  const showOrders = featureFlags.ordersDisplayOrdersPageNavigation;
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggle = () => {
    setIsModalOpen(!isModalOpen)
  }

  return <div className="app">
    <AppHeader fixed>
      <DefaultHeader user={user}/>
    </AppHeader>
    <div className="app-body">
      <AppSidebar fixed display="lg">
        <AppSidebarHeader/>
        <AppSidebarForm/>
        <AppSidebarNav>
          <SidebarTitle title={"UPLOAD"}/>
          <SidebarLink title={'Collections'} icon={'fa-thin fa-rectangle-history'} link={'/product/collections'}
                       iconFontSize={'0.875rem'} location={location} disabled
                       dataTestId={"sidebar-link-collections"}/>
          <SidebarLink title={'Marketing'} icon={'fa-thin fa-megaphone'} link={'/marketing'} iconFontSize={'0.813rem'}
                       location={location} disabled dataTestId={"sidebar-link-marketing"}/>
          <SidebarTitle title={"DATA"}/>
          <SidebarSeparator/>
          {showOrders &&
            <SidebarLink title={'Orders'} icon={'fa-thin fa-clipboard-list'} link={'/orders'} location={location}
                         disabled dataTestId={"sidebar-link-orders"}/>}
          <SidebarLink title={'Products'} icon={'fa-thin fa-shirt'} link={'/product/catalog'} iconFontSize={'0.875rem'}
                       location={location} disabled/>
          <SidebarTitle title={"CONNECTIONS"}/>
          <SidebarSeparator/>
          <SidebarLink title={'Outputs'} icon={'fa-thin fa-bolt-lightning'} link={'/output'}
                       location={location} disabled dataTestId={"sidebar-link-output"}/>
          <SidebarLink title={'Retailers'} icon={'fa-thin fa-store'} link={'/distributedata/retailers'}
                       iconFontSize={'0.875rem'} location={location} disabled dataTestId={"sidebar-link-retailers"}/>
        </AppSidebarNav> <AppSidebarFooter/>
        <AppSidebarMinimizer/>
      </AppSidebar>
      <main className="main">
        <Container className={"p-4 m-2"} fluid>
          <Card className={"d-flex align-items-center justify-content-center " + styles.card} border>
            <i className={"mb-4 " + styles.outerCircle}>
              <i className={"m-2 d-flex align-items-center justify-content-center " + styles.innerCircle}>
                <i className={"fas fa-check " + styles.icon}/>
              </i>
            </i>
            <h3 className={"mb-1 " + styles.title}>Your Account Has Been Created</h3>
            <p className={"mb-4 " + styles.paragraph}>Add your brands to begin using our product.</p>
            <MidButton variant={"secondary"} onClick={() => toggle()}>
              Add a Brand
            </MidButton>
          </Card>
          {isModalOpen && <AddCompanyModal isModalOpen={isModalOpen}
                                           toggle={toggle}
                                           id={user.company.id}
          />}
        </Container>
      </main>
    </div>
  </div>
}
