import React from 'react';
import UserContext from "../../context/UserContext";
import {SidebarLink, SidebarSeparator, SidebarTitle} from "../../ui-kit/src/sidebar/SidebarComponents";
import {Redirect, useLocation} from "react-router-dom";
import featureFlags from "../../featureFlags";
import {useFeatureFlag} from "../../ui-kit/src/context/FeatureFlagsContext";
import {
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
  //@ts-ignore
} from "@coreui/react";

export default function DefaultSidebar() {
  const location = useLocation();
  const showOrders = featureFlags.ordersDisplayOrdersPageNavigation;
  const showImages = useFeatureFlag("images.image_upload_batch").active;

  return (
    <AppSidebar fixed display="lg" >
      <AppSidebarHeader />
      <AppSidebarForm/>
      <UserContext.Consumer>
        {userInfo => <AppSidebarNav>
          <SidebarTitle title={"UPLOAD"} />
          <SidebarLink title={'Collections'} icon={'fa-thin fa-rectangle-history'} link={'/product/collections'}
                       iconFontSize={'0.875rem'} location={location} dataTestId={"sidebar-link-collections"}/>
          {showImages
            ? <SidebarLink title={'Images'} icon={'fa-thin fa-image'} link={'/images'}
                           iconFontSize={'0.938rem'} location={location} dataTestId={"sidebar-link-images"}/>
            : <Redirect from={'/images'} to={"/"}/>}
          <SidebarLink title={'Marketing'} icon={'fa-thin fa-megaphone'} link={'/marketing'}
                       iconFontSize={'0.813rem'} location={location} dataTestId={"sidebar-link-marketing"}/>
          <SidebarTitle title={"DATA"} />
          <SidebarSeparator />
          {showOrders && <SidebarLink title={'Orders'} icon={'fa-thin fa-clipboard-list'} link={'/orders'}
                                      location={location} dataTestId={"sidebar-link-orders"}/>}
          <SidebarLink title={'Products'} icon={'fa-thin fa-shirt'} link={'/product/catalog'} iconFontSize={'0.875rem'}
                       location={location} dataTestId={"sidebar-link-products"}/>
          <SidebarTitle title={"CONNECTIONS"} />
          <SidebarSeparator />
          {userInfo?.isSystemAdmin && <SidebarLink title={'Input'} icon={'fa-thin fa-right-to-bracket'} link={'/inputs'}
                                                  location={location} dataTestId={"sidebar-link-input"}/>}
          <SidebarLink title={'Outputs'} icon={'fa-thin fa-bolt-lightning'} link={'/output'}
                       location={location} dataTestId={"sidebar-link-output"}/>
          <SidebarLink title={'Retailers'} icon={'fa-thin fa-store'} link={'/distributedata/retailers'}
                       iconFontSize={'0.875rem'} location={location} dataTestId={"sidebar-link-retailers"}/>
        </AppSidebarNav>}
      </UserContext.Consumer>
      <AppSidebarFooter/>
      <AppSidebarMinimizer />
    </AppSidebar>
  )
}